* {
  box-sizing: border-box;
  color: #333;
}

body {
  background-color: #81a69b;
  background-image: url(https://pets-images.dev-apis.com/pets/wallpaperB.jpg);
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.search-params {
  width: 1100px;
  margin: 0 auto;
}

.search-params form {
  border-radius: 6px;
  background: #faeff0;
  box-shadow: 0px 0px 12px #aaa, -0px -0px 12px #fff;
  width: 360px;
  margin: 0px 25px 0px 0px;
  padding: 35px 15px 15px 15px;
  float: left;
}

.search {
  border-radius: 6px;
  background: #faeff0;
  box-shadow: 0px 0px 12px #aaa, -0px -0px 12px #fff;
  width: 715px;
  padding: 15px;
  float: left;
  margin-bottom: 25px;
}

.details {
  width: 1100px;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 6px;
  background: #faeff0;
  box-shadow: 0px 0px 12px #aaa, -0px -0px 12px #fff;
}

.pet {
  width: 100%;
  height: 130px;
  display: block;
  overflow: hidden;
  margin: 25px 0;
  border-bottom: #333 2px solid;
}

.pet img {
  width: 100px;
  min-height: 100px;
}

.info {
  float: left;
  width: 80%;
  height: 100px;
  padding-top: 10px;
  flex-direction: column;
  justify-content: space-around;
}

.image-container {
  clip-path: circle(50% at 50% 50%);
  width: 100px;
  height: 100px;
  float: left;
  margin: 0px 20px 0px 10px;
}

.pet h1 {
  white-space: nowrap;
  font-weight: normal;
  font-size: 30px;
  color: #333;
  width: 95%;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
}

header > a,
#root > div > h1 {
  display: inline-block;
  background-image: url(https://static.frontendmasters.com/resources/2019-05-02-complete-intro-react-v5/image-logo.png);
  filter: brightness(150%);
  width: 279px;
  height: 76px;
  overflow: hidden;
  text-indent: -9999px;
  margin: 20px 0;
}

.pet h2 {
  white-space: nowrap;
  font-weight: normal;
  font-size: 20px;
  margin: 0;
  text-overflow: ellipsis;
}

header a {
  color: #333;
  font-size: 3px;
  text-decoration: none;
  font-weight: bold;
  display: block;
  padding-bottom: 10px;
}

header {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 1100px;
  margin: 0 auto;
  padding: 20px 0px 0px 0px;
}

.details p {
  line-height: 1.5;
  padding: 0px 15px;
}

.details h1 {
  text-align: center;
  color: #333;
  font-size: 60px;
  margin: 5px 0px;
}

.details h2 {
  text-align: center;
  margin: 5px 0px 20px 0px;
}

.carousel {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 400px;
  margin-top: 8px;
}

.carousel > img {
  max-width: 45%;
  max-height: 400px;
}

.carousel-smaller {
  width: 50%;
}

.carousel-smaller > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  margin: 15px;
  cursor: pointer;
  border: 2px solid #333;
}

.carousel-smaller > img.active {
  border-color: #333;
  opacity: 0.6;
}

.search-params label {
  display: block;
  width: 60px;
}

.search-params input,
.search-params select {
  margin-bottom: 30px;
  font-size: 18px;
  height: 30px;
  width: 325px;
}

.search-params button,
#modal button,
.details button {
  background-color: #ad343e;
  padding: 5px 25px;
  color: white;
  font-size: 18px;
  border: #333 1px solid;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.search-params button:hover {
  background-color: #122622;
}

.search-params button:active {
  background-color: #5f1d22;
}

.search-params button:focus {
  border: 1px solid cornflowerblue;
}

#modal {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal:empty {
  display: none;
}

#modal > div {
  background-color: white;
  max-width: 500px;
  padding: 15px;
  text-align: center;
  border-radius: 30px;
  background: #faeff0;
}

#modal .buttons button {
  display: inline-block;
  margin-right: 15px;
}

@media only screen and (max-width: 1129px) {
  header {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
    padding: 20px 0px 0px 0px;
  }

  .search-params {
    width: 95%;
  }

  .search-params form {
    margin-bottom: 20px;
  }

  .search {
    width: 55%;
    padding: 15px;
    border-radius: 9px;
    float: left;
    margin-bottom: 25px;
  }

  .details {
    width: 95%;
    margin: 0 auto;
    padding: 15px;
    border-radius: 9px;
    margin-bottom: 25px;
  }

  .info {
    width: 65%;
  }

  .info h1 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .info h2 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 945px) {
  .search-params {
    width: 95%;
  }

  .search {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .pet {
    height: 200px;
  }

  .info {
    width: 100%;
    text-align: center;
  }

  .image-container {
    margin: 0 auto;
    float: none;
  }
  .carousel-smaller > img {
    width: 60px;
    height: 60px;
  }
}
